<template>
  <v-container class="mt-10 px-md-16" fluid>
    <v-row v-if="branches.length" class="mt-5" dense>
      <v-col v-for="(branch, index) in branches" :key="index" cols="12" sm="6" md="4" xl="3">
        <kurcc-site-card :branch="branch"/>
      </v-col>
    </v-row>
    <v-row v-else class="mt-5" dense>
      <v-col v-for="n in 3" :key="n" :cols="3">
        <v-skeleton-loader type="card"/>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: 'KurccFavoritesPage',
  components: {
    KurccSiteCard: () => import('@/modules/home/components/KurccSiteCard')
  },
  data () {
    return {
      loadingBranches: false,
      branches: []
    }
  },
  methods: {
    getAllBranches () {
      this.loadingBranches = true
      this.$store.dispatch('getAllBranches', {
        perPage: 5,
        currentPage: 1,
        withIncludes: true,
        orderBy: 'created_at',
        sortedBy: 'desc',
        additionalParams: ['is_favorited=1']
      }).then(res => {
        this.branches = res.items
      }).finally(() => {
        this.loadingBranches = false
      })
    }
  },
  created () {
    this.getAllBranches()
  }
}
</script>
